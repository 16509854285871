import About from "./Components/About";
import AllInOne from "./Components/AllInOne";
import Footer from "./Components/Footer";
import Home from "./Components/Home";
import Navbar from "./Components/Navbar";
import Pricing from "./Components/Pricing";
import Support from "./Components/Support";
import { Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <Navbar />
      <Home />
      {/* <Support />
      <About />
      <AllInOne />
      <Pricing />
      <Footer /> */}
    </>
  );
}

export default App;
